.button-bird {
    width: 100%;
    height: 60px;
    // background-color: #131335;
    border-radius: 5px;
    top: 50%;
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
  }
  .button-bird.disabled {
    background-color: gray;
    cursor: not-allowed;
    transition: 500ms;
  }
  .button-bird.disabled .b {
    display: none;
  }
  .hideBirds {
    opacity: 0;
  }
  .showBirds {
      opacity: 1;
      transition: 1500ms;
  }
  .button-bird__text {
    text-align: center;
    font-size: 20px;
    font-family: Helvetica, Arial, sans-serif;
    color: white;
    position: absolute;
    z-index: 1000;
    top: 50%;
    margin: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .button-bird:focus {
    outline: none;
  }
  .button-bird:hover .bird--30 {
    left: 600px;
    top: -550px;
    opacity: 0;
    transition: left 1.5s cubic-bezier(0.42, 0, 0.58, 1), top 1.5s cubic-bezier(0.42, 0, 0.58, 1), opacity 0.5s linear 1s;
  }
  .button-bird:hover .bird--30:after {
    left: 50px;
    top: 50px;
    transition: all 1.5s cubic-bezier(0.42, 0, 0.58, 1) -0.5s;
  }
  .button-bird:hover .bird--30:before {
    left: -50px;
    top: 50px;
    transition: all 1.5s cubic-bezier(0.42, 0, 0.58, 1) -0.5s;
  }
  .button-bird .feather {
    position: absolute;
    width: 18%;
    left: 40%;
    top: 12px;
    display: none;
    opacity: 0;
    fill: #131335;
  }
  
  .button-bird.active {
    background-color: transparent;
    transition: all 0.2s linear 0.1s;
  }
  .button-bird.active .button-bird__text {
    color: #131335;
    -webkit-animation: text-fade 1.5s 0.2s;
            animation: text-fade 1.5s 0.2s;
  }
  .button-bird.active .feather {
    display: block;
    -webkit-animation: feather-fade 1.5s linear 0.5s forwards;
            animation: feather-fade 1.5s linear 0.5s forwards;
  }
  .button-bird.active .bird--20,
  .button-bird.active .bird--19,
  .button-bird.active .bird--18,
  .button-bird.active .bird--21,
  .button-bird.active .bird--23,
  .button-bird.active .bird--25,
  .button-bird.active .bird--30 {
    left: 600px;
    top: -550px;
    opacity: 0;
    transition: left 2s cubic-bezier(0.42, 0, 0.58, 1), top 2s cubic-bezier(0.42, 0, 0.58, 1), opacity 0.5s linear 1.5s;
  }
  .button-bird.active .bird--5,
  .button-bird.active .bird--17,
  .button-bird.active .bird--16,
  .button-bird.active .bird--15,
  .button-bird.active .bird--22 {
    left: 600px;
    top: -600px;
    opacity: 0;
    transition: left 2s cubic-bezier(0.42, 0, 0.58, 1) 0.1s, top 2s cubic-bezier(0.42, 0, 0.58, 1) 0.1s, opacity 0.5s linear 1.6s;
  }
  .button-bird.active .bird--14,
  .button-bird.active .bird--13,
  .button-bird.active .bird--12,
  .button-bird.active .bird--24,
  .button-bird.active .bird--1 {
    left: 650px;
    top: -650px;
    opacity: 0;
    transition: left 2s cubic-bezier(0.42, 0, 0.58, 1) 0.15s, top 2s cubic-bezier(0.42, 0, 0.58, 1) 0.15s, opacity 0.5s linear 1.65s;
  }
  .button-bird.active .bird--11,
  .button-bird.active .bird--10,
  .button-bird.active .bird--9,
  .button-bird.active .bird--26,
  .button-bird.active .bird--27 {
    left: 650px;
    top: -700px;
    opacity: 0;
    transition: left 2s cubic-bezier(0.42, 0, 0.58, 1) 0.2s, top 2s cubic-bezier(0.42, 0, 0.58, 1) 0.2s, opacity 0.5s linear 1.7s;
  }
  .button-bird.active .bird--8,
  .button-bird.active .bird--7,
  .button-bird.active .bird--6,
  .button-bird.active .bird--28 {
    left: 700px;
    top: -750px;
    opacity: 0;
    transition: left 2s cubic-bezier(0.42, 0, 0.58, 1) 0.25s, top 2s cubic-bezier(0.42, 0, 0.58, 1) 0.25s, opacity 0.5s linear 1.75s;
  }
  .button-bird.active .bird,
  .button-bird.active .bird--4,
  .button-bird.active .bird--29,
  .button-bird.active .bird--3 {
    left: 750px;
    top: -800px;
    opacity: 0;
    transition: left 2s cubic-bezier(0.42, 0, 0.58, 1) 0.3s, top 2s cubic-bezier(0.42, 0, 0.58, 1) 0.3s, opacity 0.5s linear 1.8s;
  }
  .button-bird.active .bird--2 {
    left: 850px;
    top: -850px;
    opacity: 0;
    transition: left 1.5s cubic-bezier(0.42, 0, 0.58, 1) 0.35s, top 1.5s cubic-bezier(0.42, 0, 0.58, 1) 0.35s, opacity 0.5s linear 1.8s;
  }
  .button-bird.active .bird--5:after,
  .button-bird.active .bird--4:after,
  .button-bird.active .bird--8:after,
  .button-bird.active .bird--14:before,
  .button-bird.active .bird--16:after,
  .button-bird.active .bird--17:before,
  .button-bird.active .bird--21:after,
  .button-bird.active .bird--30:after,
  .button-bird.active .bird--30:before {
    left: 50px;
    top: -100px;
    transition: all 1s cubic-bezier(0.42, 0, 0.58, 1) -0.3s;
  }
  .button-bird.active .bird--4:before,
  .button-bird.active .bird--7:before,
  .button-bird.active .bird--9:after,
  .button-bird.active .bird--12:before,
  .button-bird.active .bird--13:before,
  .button-bird.active .bird--18:after,
  .button-bird.active .bird--27:after,
  .button-bird.active .bird--26:before {
    left: -50px;
    top: 0px;
    transition: all ss cubic-bezier(0.42, 0, 0.58, 1) -0.5s;
  }
  .button-bird.active .bird:before,
  .button-bird.active .bird--2:after,
  .button-bird.active .bird--1:after,
  .button-bird.active .bird--7:after,
  .button-bird.active .bird--8:before,
  .button-bird.active .bird--15:after,
  .button-bird.active .bird--19:before,
  .button-bird.active .bird--22:after,
  .button-bird.active .bird--28:after,
  .button-bird.active .bird--28:before,
  .button-bird.active .bird--3:after {
    left: 100px;
    top: -100px;
    transition: all 1s cubic-bezier(0.42, 0, 0.58, 1) 0s;
  }
  .button-bird.active .bird--2:before,
  .button-bird.active .bird--1:before,
  .button-bird.active .bird--9:before,
  .button-bird.active .bird--12:after,
  .button-bird.active .bird--13:after,
  .button-bird.active .bird--17:after,
  .button-bird.active .bird--19:before,
  .button-bird.active .bird--23:after,
  .button-bird.active .bird--23:before {
    left: 50px;
    top: -10px;
    transition: all 2s cubic-bezier(0.42, 0, 0.58, 1) -0.5s;
  }
  .button-bird.active .bird:after,
  .button-bird.active .bird--6:before,
  .button-bird.active .bird--10:after,
  .button-bird.active .bird--11:after,
  .button-bird.active .bird--16:before,
  .button-bird.active .bird--18:before,
  .button-bird.active .bird--20:before,
  .button-bird.active .bird--24:before,
  .button-bird.active .bird--26:after,
  .button-bird.active .bird--27:before {
    left: 50px;
    top: 50px;
    transition: all 2s cubic-bezier(0.42, 0, 0.58, 1) -0.5s;
  }
  .button-bird.active .bird--3:before,
  .button-bird.active .bird--6:after,
  .button-bird.active .bird--10:before,
  .button-bird.active .bird--11:before,
  .button-bird.active .bird--14:after,
  .button-bird.active .bird--15:before,
  .button-bird.active .bird--20:after,
  .button-bird.active .bird--22:before,
  .button-bird.active .bird--24:after,
  .button-bird.active .bird--25:after,
  .button-bird.active .bird--25:before,
  .button-bird.active .bird--5:before {
    left: 100px;
    top: -10px;
    transition: all 2s cubic-bezier(0.42, 0, 0.58, 1) -0.5s;
  }
  
  .bird,
  .bird:before,
  .bird:after,
  .bird--1,
  .bird--1:after,
  .bird--1:before,
  .bird--2,
  .bird--2:after,
  .bird--2:before,
  .bird--3,
  .bird--3:after,
  .bird--3:before,
  .bird--4,
  .bird--4:after,
  .bird--4:before,
  .bird--5,
  .bird--5:after,
  .bird--5:before,
  .bird--6,
  .bird--6:after,
  .bird--6:before,
  .bird--7,
  .bird--7:after,
  .bird--7:before,
  .bird--8,
  .bird--8:after,
  .bird--8:before,
  .bird--9,
  .bird--9:after,
  .bird--9:before,
  .bird--10,
  .bird--10:after,
  .bird--10:before,
  .bird--11,
  .bird--11:after,
  .bird--11:before,
  .bird--12,
  .bird--12:after,
  .bird--12:before,
  .bird--13,
  .bird--13:after,
  .bird--13:before,
  .bird--14,
  .bird--14:after,
  .bird--14:before,
  .bird--15,
  .bird--15:after,
  .bird--15:before,
  .bird--16,
  .bird--16:after,
  .bird--16:before,
  .bird--17,
  .bird--17:after,
  .bird--17:before,
  .bird--18,
  .bird--18:after,
  .bird--18:before,
  .bird--19,
  .bird--19:after,
  .bird--19:before,
  .bird--20,
  .bird--20:after,
  .bird--20:before,
  .bird--21,
  .bird--21:after,
  .bird--21:before,
  .bird--22,
  .bird--22:after,
  .bird--22:before,
  .bird--23,
  .bird--23:after,
  .bird--23:before,
  .bird--24,
  .bird--24:after,
  .bird--24:before,
  .bird--25,
  .bird--25:after,
  .bird--25:before,
  .bird--26,
  .bird--26:after,
  .bird--26:before,
  .bird--27,
  .bird--27:after,
  .bird--27:before,
  .bird--28,
  .bird--28:after,
  .bird--28:before,
  .bird--29,
  .bird--29:after,
  .bird--29:before,
  .bird--30,
  .bird--30:after,
  .bird--30:before {
    opacity: 1;
    display: block;
    position: absolute;
    background-image: url("/birds.svg");
    background-size: auto 100%;
    width: 60px;
    height: 33px;
    top: 0;
    left: 0;
    -webkit-animation-name: fly-cycle;
            animation-name: fly-cycle;
    -webkit-animation-timing-function: steps(3);
            animation-timing-function: steps(3);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  
  .bird--1:after,
  .bird--1:before,
  .bird--2:after,
  .bird--2:before,
  .bird--4:after,
  .bird--4:before,
  .bird--5:after,
  .bird--5:before,
  .bird--7:after,
  .bird--7:before,
  .bird--8:after,
  .bird--8:before,
  .bird--10:after,
  .bird--10:before,
  .bird--11:after,
  .bird--11:before,
  .bird--13:after,
  .bird--13:before,
  .bird--14:after,
  .bird--14:before,
  .bird--16:after,
  .bird--16:before,
  .bird--17:after,
  .bird--17:before,
  .bird--18,
  .bird--18:after,
  .bird--18:before,
  .bird--19,
  .bird--19:after,
  .bird--19:before,
  .bird--20:after,
  .bird--20:before,
  .bird--21,
  .bird--21:after,
  .bird--21:before,
  .bird--22,
  .bird--22:after,
  .bird--22:before,
  .bird--23,
  .bird--23:after,
  .bird--23:before,
  .bird--24,
  .bird--24:after,
  .bird--24:before,
  .bird--25,
  .bird--25:after,
  .bird--25:before,
  .bird--26,
  .bird--26:after,
  .bird--26:before,
  .bird--27,
  .bird--27:after,
  .bird--27:before,
  .bird--28,
  .bird--28:after,
  .bird--28:before,
  .bird--29,
  .bird--29:after,
  .bird--29:before,
  .bird--30,
  .bird--30:after,
  .bird--30:before {
    width: 30px;
    height: 33px;
    top: -8px;
    left: 8px;
    z-index: -100;
  }
  
  .bird--21,
  .bird--22,
  .bird--23,
  .bird--24,
  .bird--25,
  .bird--26,
  .bird--27,
  .bird--28,
  .bird--29 {
    top: 0;
  }
  
  .bird--22,
  .bird--25,
  .bird--28 {
    top: 25px;
  }
  
  .bird--23,
  .bird--26,
  .bird--29 {
    top: 45px;
  }
  
  .bird--24,
  .bird--25,
  .bird--26 {
    left: 133px;
  }
  
  .bird--27,
  .bird--28,
  .bird--29 {
    left: 256px;
  }
  
  .bird--18 {
    top: 0;
    left: 0;
  }
  
  .bird--18:after,
  .bird--18:before {
    left: -20px;
  }
  
  .bird--19:after,
  .bird--19:before {
    left: -10px;
  }
  
  .bird--1:before,
  .bird--4:before,
  .bird--7:before,
  .bird--10:before,
  .bird--13:before,
  .bird--16:before,
  .bird--19:before {
    top: 18px;
  }
  
  .bird--2:after,
  .bird--5:after,
  .bird--8:after,
  .bird--11:after,
  .bird--14:after,
  .bird--17:after,
  .bird--20:after {
    top: -18px;
    left: 12px;
  }
  
  .bird--20:after,
  .bird--20:before,
  .bird--21:after {
    left: 27px;
  }
  
  .bird--2:before,
  .bird--5:before,
  .bird--8:before,
  .bird--11:before,
  .bird--14:before,
  .bird--17:before,
  .bird--20:before {
    top: -36px;
    left: 12px;
  }
  
  .bird--21:before,
  .bird--22:before,
  .bird--23:before,
  .bird--24:before,
  .bird--25:before,
  .bird--26:before {
    top: 10px;
    left: 45px;
  }
  
  .bird--21:after,
  .bird--22:after,
  .bird--23:after,
  .bird--24:after,
  .bird--25:after,
  .bird--26:after,
  .bird--27:after,
  .bird--28:after,
  .bird--29:after {
    left: 10px;
    top: 4px;
  }
  
  .bird--27:before,
  .bird--28:before,
  .bird--29:before {
    left: -45px;
    top: 10px;
  }
  
  .bird:before,
  .bird:after,
  .bird--1:after,
  .bird--1:before,
  .bird--2:before,
  .bird--2:after,
  .bird--3:after,
  .bird--3:before,
  .bird--4:after,
  .bird--4:before,
  .bird--5:after,
  .bird--5:before,
  .bird--6:after,
  .bird--6:before,
  .bird--7:after,
  .bird--7:before,
  .bird--8:after,
  .bird--8:before,
  .bird--9:after,
  .bird--9:before,
  .bird--10:after,
  .bird--10:before,
  .bird--11:after,
  .bird--11:before,
  .bird--12:after,
  .bird--12:before,
  .bird--13:after,
  .bird--13:before,
  .bird--14:after,
  .bird--14:before,
  .bird--15:after,
  .bird--15:before,
  .bird--16:after,
  .bird--16:before,
  .bird--17:after,
  .bird--17:before,
  .bird--18:after,
  .bird--18:before,
  .bird--19:after,
  .bird--19:before,
  .bird--20:after,
  .bird--20:before,
  .bird--21:after,
  .bird--21:before,
  .bird--22:after,
  .bird--22:before,
  .bird--23:after,
  .bird--23:before,
  .bird--24:after,
  .bird--24:before,
  .bird--25:after,
  .bird--25:before,
  .bird--26:after,
  .bird--26:before,
  .bird--27:after,
  .bird--27:before,
  .bird--28:after,
  .bird--28:before,
  .bird--29:after,
  .bird--29:before,
  .bird--30:after,
  .bird--30:before {
    content: '';
  }
  
  .bird:before,
  .bird--3:before,
  .bird--12:before,
  .bird--15:before,
  .bird--6:before,
  .bird--9:before,
  .bird--18:before {
    top: 10px;
  }
  
  .bird:after,
  .bird--6:after,
  .bird--12:after,
  .bird--15:after,
  .bird--9:after,
  .bird--18:after {
    top: 35px;
  }
  
  .bird--3:after {
    left: -10px;
  }
  
  .bird--3:after {
    top: 40px;
  }
  
  .bird--1,
  .bird--4,
  .bird--7,
  .bird--10,
  .bird--13,
  .bird--16,
  .bird--19 {
    top: 25px;
  }
  
  .bird--2 {
    top: 45px;
  }
  
  .bird--5 {
    top: 47px;
    left: 25px;
  }
  
  .bird--8,
  .bird--11,
  .bird--14,
  .bird--17,
  .bird--20 {
    top: 50px;
  }
  
  .bird--3,
  .bird--4 {
    left: 59px;
  }
  
  .bird--6,
  .bird--7,
  .bird--8 {
    left: 118px;
  }
  
  .bird--9,
  .bird--10,
  .bird--11 {
    left: 177px;
  }
  
  .bird--12,
  .bird--13,
  .bird--14 {
    left: 236px;
  }
  
  .bird--15,
  .bird--16,
  .bird--17 {
    left: 206.5px;
  }
  
  .bird--20 {
    left: 108px;
  }
  
  .bird--18,
  .bird--19 {
    left: 137.5px;
  }
  
  .bird--30,
  .bird--30:after,
  .bird--30:before {
    left: 118px;
    top: 0;
  }
  
  .bird--30:before {
    top: 25px;
  }
  
  .bird--30:after {
    top: 20px;
  }
  
  .active .bird,
  .active .bird:before,
  .active .bird--3,
  .active .bird--4,
  .active .bird--4:after,
  .active .bird--4:before,
  .active .bird--9,
  .active .bird--9:after,
  .active .bird--13,
  .active .bird--13:after,
  .active .bird--13:before,
  .active .bird--15,
  .active .bird--16,
  .active .bird--18,
  .active .bird--21,
  .active .bird--21:after,
  .active .bird--27:before,
  .active .bird--24,
  .active .bird--24:after,
  .active .bird--24:before,
  .active .bird--27,
  .active .bird--27:after,
  .active .bird--27:before,
  .active .bird--30:before,
  .button-bird:hover .bird,
  .button-bird:hover .bird:before,
  .button-bird:hover .bird--3,
  .button-bird:hover .bird--4,
  .button-bird:hover .bird--4:after,
  .button-bird:hover .bird--4:before,
  .button-bird:hover .bird--9,
  .button-bird:hover .bird--9:after,
  .button-bird:hover .bird--13,
  .button-bird:hover .bird--13:after,
  .button-bird:hover .bird--13:before,
  .button-bird:hover .bird--15,
  .button-bird:hover .bird--16,
  .button-bird:hover .bird--18,
  .button-bird:hover .bird--21,
  .button-bird:hover .bird--21:after,
  .button-bird:hover .bird--27:before,
  .button-bird:hover .bird--24,
  .button-bird:hover .bird--24:after,
  .button-bird:hover .bird--24:before,
  .button-bird:hover .bird--27,
  .button-bird:hover .bird--27:after,
  .button-bird:hover .bird--27:before,
  .button-bird:hover .bird--30:before {
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  .active .bird--1,
  .active .bird--1:after,
  .active .bird--3:before,
  .active .bird--5,
  .active .bird--5:after,
  .active .bird--5:before,
  .active .bird--7,
  .active .bird--7:after,
  .active .bird--7:before,
  .active .bird--9:before,
  .active .bird--10,
  .active .bird--10:after,
  .active .bird--10:before,
  .active .bird--14,
  .active .bird--14:after,
  .active .bird--14:before,
  .active .bird--17,
  .active .bird--17:after,
  .active .bird--17:before,
  .active .bird--19,
  .active .bird--19:after,
  .active .bird--19:before,
  .active .bird--18:after,
  .active .bird--22,
  .active .bird--22:after,
  .active .bird--22:before,
  .active .bird--25,
  .active .bird--25:after,
  .active .bird--25:before,
  .active .bird--28,
  .active .bird--28:after,
  .active .bird--28:before,
  .active .bird--30:after,
  .button-bird:hover .bird--1,
  .button-bird:hover .bird--1:after,
  .button-bird:hover .bird--3:before,
  .button-bird:hover .bird--5,
  .button-bird:hover .bird--5:after,
  .button-bird:hover .bird--5:before,
  .button-bird:hover .bird--7,
  .button-bird:hover .bird--7:after,
  .button-bird:hover .bird--7:before,
  .button-bird:hover .bird--9:before,
  .button-bird:hover .bird--10,
  .button-bird:hover .bird--10:after,
  .button-bird:hover .bird--10:before,
  .button-bird:hover .bird--14,
  .button-bird:hover .bird--14:after,
  .button-bird:hover .bird--14:before,
  .button-bird:hover .bird--17,
  .button-bird:hover .bird--17:after,
  .button-bird:hover .bird--17:before,
  .button-bird:hover .bird--19,
  .button-bird:hover .bird--19:after,
  .button-bird:hover .bird--19:before,
  .button-bird:hover .bird--18:after,
  .button-bird:hover .bird--22,
  .button-bird:hover .bird--22:after,
  .button-bird:hover .bird--22:before,
  .button-bird:hover .bird--25,
  .button-bird:hover .bird--25:after,
  .button-bird:hover .bird--25:before,
  .button-bird:hover .bird--28,
  .button-bird:hover .bird--28:after,
  .button-bird:hover .bird--28:before,
  .button-bird:hover .bird--30:after {
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  .active .bird:after,
  .active .bird--1:before,
  .active .bird--2,
  .active .bird--2:before,
  .active .bird--2:after,
  .active .bird--3:after,
  .active .bird--6,
  .active .bird--6:after,
  .active .bird--6:before,
  .active .bird--8,
  .active .bird--8:after,
  .active .bird--8:before,
  .active .bird--11,
  .active .bird--11:after,
  .active .bird--11:before,
  .active .bird--12,
  .active .bird--12:after,
  .active .bird--12:before,
  .active .bird--16,
  .active .bird--16:after,
  .active .bird--16:before,
  .active .bird--20,
  .active .bird--20:after,
  .active .bird--20:before,
  .active .bird--18:before,
  .active .bird--23,
  .active .bird--23:after,
  .active .bird--23:before,
  .active .bird--26,
  .active .bird--26:after,
  .active .bird--26:before,
  .active .bird--29,
  .active .bird--29:after,
  .active .bird--29:before,
  .active .bird--30,
  .button-bird:hover .bird:after,
  .button-bird:hover .bird--1:before,
  .button-bird:hover .bird--2,
  .button-bird:hover .bird--2:before,
  .button-bird:hover .bird--2:after,
  .button-bird:hover .bird--3:after,
  .button-bird:hover .bird--6,
  .button-bird:hover .bird--6:after,
  .button-bird:hover .bird--6:before,
  .button-bird:hover .bird--8,
  .button-bird:hover .bird--8:after,
  .button-bird:hover .bird--8:before,
  .button-bird:hover .bird--11,
  .button-bird:hover .bird--11:after,
  .button-bird:hover .bird--11:before,
  .button-bird:hover .bird--12,
  .button-bird:hover .bird--12:after,
  .button-bird:hover .bird--12:before,
  .button-bird:hover .bird--16,
  .button-bird:hover .bird--16:after,
  .button-bird:hover .bird--16:before,
  .button-bird:hover .bird--20,
  .button-bird:hover .bird--20:after,
  .button-bird:hover .bird--20:before,
  .button-bird:hover .bird--18:before,
  .button-bird:hover .bird--23,
  .button-bird:hover .bird--23:after,
  .button-bird:hover .bird--23:before,
  .button-bird:hover .bird--26,
  .button-bird:hover .bird--26:after,
  .button-bird:hover .bird--26:before,
  .button-bird:hover .bird--29,
  .button-bird:hover .bird--29:after,
  .button-bird:hover .bird--29:before,
  .button-bird:hover .bird--30 {
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
    -webkit-animation-delay: -0.75s;
            animation-delay: -0.75s;
  }
  
  @-webkit-keyframes fly-cycle {
    100% {
      background-position: -360px 0;
    }
  }
  
  @keyframes fly-cycle {
    100% {
      background-position: -360px 0;
    }
  }
  @-webkit-keyframes text-fade {
    0% {
      color: transparent;
      opacity: 1;
      top: -50%;
      left: auto;
    }
    1% {
      color: transparent;
      opacity: 0;
    }
    50% {
      color: transparent;
      opacity: 0;
    }
    100% {
      color: #131335;
      opacity: 1;
    }
  }
  @keyframes text-fade {
    0% {
      color: transparent;
      opacity: 1;
      top: -50%;
      left: auto;
    }
    1% {
      color: transparent;
      opacity: 0;
    }
    50% {
      color: transparent;
      opacity: 0;
    }
    100% {
      color: #131335;
      opacity: 1;
    }
  }
  @-webkit-keyframes feather-fade {
    0% {
      top: -100px;
      opacity: 0;
    }
    25% {
      -webkit-transform: rotate(10deg);
              transform: rotate(10deg);
      left: 30%;
    }
    50% {
      -webkit-transform: rotate(-5deg);
              transform: rotate(-5deg);
      opacity: 1;
      left: 45%;
    }
    75% {
      -webkit-transform: rotate(10deg);
              transform: rotate(10deg);
      left: 32%;
    }
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      opacity: 1;
      top: 12px;
      left: 43%;
    }
  }
  @keyframes feather-fade {
    0% {
      top: -100px;
      opacity: 0;
    }
    25% {
      -webkit-transform: rotate(10deg);
              transform: rotate(10deg);
      left: 30%;
    }
    50% {
      -webkit-transform: rotate(-5deg);
              transform: rotate(-5deg);
      opacity: 1;
      left: 45%;
    }
    75% {
      -webkit-transform: rotate(10deg);
              transform: rotate(10deg);
      left: 32%;
    }
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      opacity: 1;
      top: 12px;
      left: 43%;
    }
  }
  